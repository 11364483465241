
import { defineComponent, onMounted, ref, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useToast } from "vue-toastification";
import { hideModal } from "@/core/helpers/dom";

import DaftarPSP from "@/components/1crm/siswa/daftar-psp.vue";

import moment from "moment";

// Import loading overlay
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
// end import loading overlay

export default defineComponent({
  name: "History Detail Migrasi",
  components: {
    Field,
    DaftarPSP,
    Loading, //loading overlay
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      // loading overlay setting
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
      // end loading overlay setting

      disableButton: false,
      tableData: [],
      processId: "",
      paymentId: "",
      schoolId: "",
      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },
    };
  },

  beforeMount() {
    this.schoolId = JSON.parse(localStorage.getItem("user_account")!).schoolId;
    this.processId = JSON.parse(localStorage.getItem("processId")!);
    this.paymentId = JSON.parse(localStorage.getItem("paymentId")!);
    this.getData(this.paging.size, this.paging.page);
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    getData(size, page) {
      // loading overlay tampil
      this.isLoading = true;

      const toast = useToast();

      ApiService.getWithoutSlug(
        "crmv2/main_student/student/office/log_migration/" +
          this.processId +
          "?page=" +
          page +
          "&size=" +
          size +
          "&sort=createTime&dir=1"
      )
        .then(({ data }) => {
          this.tableData = data.content;
          this.paging.page = data.page;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;

          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },
    buttondownload() {
      const toast = useToast();
      var linka = "";
      // console.log("this.schoolId", this.schoolId);
      // console.log("this.processId", this.processId);
      const link = document.createElement("a");
      ApiService.getDownload(
        `crmv2/main_student/student/office/log_migration/download/history_migration.xlsx?schoolId=${this.schoolId}&processId=${this.processId}`
      )
        .then((res) => {
          linka = "" + res.config.baseURL + res.config.url;

          console.log(res);
          this.handleBlobResponse(res.data, "xlsx");
          toast.success("Sukses Unduh File");
          // window.open(linka)
          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    handleBlobResponse(blobFileData, extension) {
      const url = window.URL.createObjectURL(new Blob([blobFileData]));
      const link = document.createElement("a");
      const d = JSON.parse(localStorage.getItem("user_account")!).name;
      link.href = url;
      link.setAttribute(
        "download",
        "History daftar Siswa" + d + "." + extension
      ); // nama file dan extension sesuaikan dengan file yang di download
      document.body.appendChild(link);
      link.click();
    },

    refreshMigrasi() {
      this.isLoading = true;

      const toast = useToast();

      ApiService.postWithoutSlug(
        "crmv2/main_student/student/office/refresh_migration_psp?schoolId=" +
          this.schoolId +
          "&paymentId=" +
          this.paymentId +
          "&processId=" +
          this.processId
      )
        .then((res) => {
          toast.success("Data Siswa Berhasil Terdaftar di PSP");
          this.getData(this.paging.size, this.paging.page);
          this.isLoading = false;
        })
        .catch((e) => {
          if (e.response.status == 304) {
            toast.success("Data Siswa Berhasil Terdaftar di PSP");
            this.isLoading = false;
          } else if (e.response.status == 401) {
            toast.error(e.response.data.detail);
            this.$router.push("/sign-in");
            this.isLoading = false;
          } else {
            toast.error(e.response.data.detail);
            this.isLoading = false;
          }
        });
    },

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize(e) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },
  },
});
