
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useToast } from "vue-toastification";
import { ref } from "yup";

import HistorySiswaDetail from "@/components/1crm/1sekolah/siswa/history-detail.vue";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    HistorySiswaDetail,
  },
  props: {
    widgetClasses: String,
  },

  beforeMount() {
    const store = useStore();
    store.dispatch(Actions.VERIFY_AUTH_SISWA);
    setCurrentPageBreadcrumbs("Detail History Daftar Siswa ke PSP", ["Data"]);
  },

});
